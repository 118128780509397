import { gql } from '@apollo/client';

const MoneyFields = gql`
  fragment MoneyFields on Money {
    amount
    currency {
      code
      name
    }
    chileFormat: formatted(
      groupSeparator: true
      currencyDigits: true
      decimalQuantization: true
      locale: "es_ES"
      format: "$#,###"
    )
  }
`;

export default MoneyFields;
